import { GqlListUserGroup, GqlUserResetPassword, LocalUserData, RestListMenuUser } from 'data/usecase';
import { Professional } from 'domain/models/data/person';
import { makeHttpRequest } from 'utils';

export const makeUserData = (professional: Professional) => {
  return new LocalUserData(professional);
};

export const makeListUserGroup = () => {
  return new GqlListUserGroup();
};

export const makeUserResetPassword = () => {
  return new GqlUserResetPassword();
};

export const makeListMenuUser = () => new RestListMenuUser(makeHttpRequest());
