import env from 'config/env';
import { Institution, Menu, Professional } from 'domain/models/data';
import { ResetPasswordMutation } from 'domain/models/graphql';
import { ListUserMenu, ResetPasswordInput, UserAuhenticated, UserGroupResponse, UserResetPassword } from 'domain/usecases';
import { ListUserGroup, UserData } from 'domain/usecases';
import { DocumentNode } from 'graphql';
import { executeMutation, executeQuery } from 'infra/graphql/client';
import { USER_RESET_PASSWORD_MUTATION } from 'infra/graphql/mutations';
import { USER_GROUP_LIST } from 'infra/graphql/queries';

import { GqlQueryData } from './gql-data';

export class LocalUserData implements UserData {
  constructor(private readonly professional: Professional) {}

  getInstitution() {
    const institution = this.professional?.institution as unknown as Institution;
    return institution?.id as string;
  }

  getPersonName() {
    const storageValue = this.professional?.name?.replaceAll('+', ' ') || '';
    return decodeURI(storageValue);
  }

  getPersonId() {
    return this.professional?.id as string;
  }

  canPerformerAttendanceProcedure() {
    return this.professional?.actionPermission?.canPerformerAttendanceProcedure || false;
  }
}

export class GqlListUserGroup extends GqlQueryData implements ListUserGroup {
  protected buildDocumentNode(): DocumentNode {
    return USER_GROUP_LIST;
  }
  protected getQueryName(): string {
    return 'listUserGroup';
  }

  async fetch() {
    const { listUserGroup } = await executeQuery<UserGroupResponse, {}>(USER_GROUP_LIST, {});
    return listUserGroup;
  }
}

export class GqlUserResetPassword implements UserResetPassword {
  async reset(input: ResetPasswordInput) {
    const { changePassword, errors } = await executeMutation<ResetPasswordMutation>(USER_RESET_PASSWORD_MUTATION, { data: input });
    return { changePassword, errors };
  }
}

export class RestListMenuUser implements ListUserMenu {
  constructor(
    private readonly http: {
      get: (url: string) => Promise<{ data: Menu[] }>;
    },
  ) {}

  async fetch() {
    const { data } = await this.http.get(`${env.API_URL}/api/user/menus`);
    return data;
  }
}
