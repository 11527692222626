import { AppContext } from 'presentation/context/AppContext';
import useLocalStorage from 'presentation/hooks/useLocalStorage';
import { ArrowDownIcon } from 'presentation/icons';
import { useContext, useEffect, useState } from 'react';

import Dropdown from '../Dropdown';

export const InstitutionUnitDropdown = () => {
  const { authProfessional: currentProfessional } = useContext(AppContext);
  const defaultUnit = currentProfessional?.institutionUnits?.at(0)?.id;
  const [institutionUnit, setInstitutionUnit] = useLocalStorage('unit', defaultUnit || '');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (institutionUnit && window.location && !loading) {
      window.location.reload();
    }
  }, [institutionUnit]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const unitName = currentProfessional?.institutionUnits?.find((unit) => unit.id === institutionUnit)?.name;

  return (
    <div className="dropdown">
      <Dropdown
        placement={'bottom-end'}
        btnClassName="btn-sm dropdown-toggle p-0"
        button={
          <div className="flex flex-row">
            {unitName}
            <ArrowDownIcon size={14} className="mt-0.5 ml-1" />
          </div>
        }
      >
        <ul className="!min-w-[170px]">
          {currentProfessional?.institutionUnits?.map((unit) => (
            <li key={unit.id}>
              <button type="button" className="text-xs" onClick={() => setInstitutionUnit(unit.id)}>
                {unit.name}
              </button>
            </li>
          ))}
        </ul>
      </Dropdown>
    </div>
  );
};
