import { Professional } from 'domain/models/data';
import { useEffect, useState } from 'react';
import { makeHttpRequest, snakeCaseToCamelCase } from 'utils';

export const useMe = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [professional, setProfessional] = useState<Professional>();
  useEffect(() => {
    const http = makeHttpRequest();
    http
      .get<Professional>('/api/me')
      .then((response) => {
        const parsed = snakeCaseToCamelCase(response.data);
        setProfessional(parsed);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, []);

  return { loading, professional, error };
};
