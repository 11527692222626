import { Institution, InstitutionUnit, Professional } from 'domain/models/data';
import { createContext } from 'react';

type AppContextType = {
  institution: Institution;
  institutionUnit: InstitutionUnit;
  authProfessional: Professional;
};

export const AppContext = createContext<AppContextType>({
  institution: {},
  institutionUnit: {},
  authProfessional: {},
});
