import { Menu } from 'domain/models/data';
import { makeUserData } from 'main/factories';
import { AppContext } from 'presentation/context/AppContext';
import { ArrowDownIcon, CertificateDuoIcon, PasswordDuoIcon } from 'presentation/icons';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { makeHttpRequest } from 'utils';

import { IRootState } from '../../store';
import { toggleSidebar } from '../../store/themeConfigSlice';
import Dropdown from '../Dropdown';
import { InstitutionUnitDropdown } from './institution-unit-dropdown';
import { getMenuIcon } from './menu-icon';

const Header = () => {
  const location = useLocation();
  const history = useNavigate();
  const { authProfessional: currentProfessional } = useContext(AppContext);
  const userData = makeUserData(currentProfessional);

  const [userMenus, setUserMenus] = useState<Menu[]>([]);
  const [currentMenu, setCurrentMenu] = useState<string>();

  useEffect(() => {
    const selector = document.querySelector('ul.horizontal-menu a[href="' + window.location.pathname + '"]');
    if (selector) {
      const all: any = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
      for (let i = 0; i < all.length; i++) {
        all[0]?.classList.remove('active');
      }
      selector.classList.add('active');
      const ul: any = selector.closest('ul.sub-menu');
      if (ul) {
        let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link');
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele?.classList.add('active');
          });
        }
      }
    }
  }, [location]);

  const isRtl = useSelector((state: IRootState) => state.themeConfig.rtlClass) === 'rtl' ? true : false;

  const themeConfig = useSelector((state: IRootState) => state.themeConfig);
  const dispatch = useDispatch();

  function logout() {
    const httpRequest = makeHttpRequest();
    httpRequest.post(`/api/logout`).finally(() => {
      setTimeout(() => {
        history('/login');
      }, 1000);
    });
  }
  const userName = userData.getPersonName();
  const getUserAbrev = () => {
    if (!userData) return '??';
    const [name, sur] = userName.split(' ');
    return name.charAt(0) + (sur ? sur.charAt(0) : name.charAt(1)).toUpperCase();
  };
  const userAbrev = getUserAbrev();

  useEffect(() => {
    console.log('currentProfessional', currentProfessional);
    const mainMenu = currentProfessional?.user?.menus.filter((m) => !m.parent) || ([] as Menu[]);
    setUserMenus(mainMenu);
  }, [currentProfessional]);

  return (
    <header className={themeConfig.semidark && themeConfig.menu === 'horizontal' ? 'dark' : ''}>
      <div className="shadow-sm">
        <div className="relative bg-white flex w-full items-center px-5 py-2.5 dark:bg-black">
          <div className="horizontal-logo flex lg:hidden justify-between items-center ltr:mr-2 rtl:ml-2">
            <Link to="/" className="main-logo flex items-center shrink-0">
              <img className="ltr:-ml-1 rtl:-mr-1 inline" width={220} src="/assets/images/sade_v2.svg" alt="logo" />
            </Link>
            <button
              type="button"
              className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60  mt-2"
              onClick={() => {
                dispatch(toggleSidebar());
              }}
            >
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 7L4 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path opacity="0.5" d="M20 12L4 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M20 17L4 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </button>
          </div>

          <div className="ltr:mr-2 rtl:ml-2 hidden sm:block">
            <ul className="flex items-center space-x-2 rtl:space-x-reverse dark:text-[#d0d2d6]">
              <li>
                <Link to="/atendimento/agenda" className="block p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60 mt-2">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path opacity="0.5" d="M7 4V2.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    <path opacity="0.5" d="M17 4V2.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    <path opacity="0.5" d="M2 9H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
          <div className="sm:flex-1 ltr:sm:ml-0 ltr:ml-auto sm:rtl:mr-0 rtl:mr-auto flex items-center space-x-1.5 lg:space-x-2 rtl:space-x-reverse dark:text-[#d0d2d6] justify-end">
            <div className="flex flex-col">
              <span className="text-primary">{userName}</span>
              <InstitutionUnitDropdown />
            </div>

            <div className="dropdown shrink-0 flex">
              <Dropdown
                offset={[0, 8]}
                placement={`${isRtl ? 'bottom-start' : 'bottom-end'}`}
                btnClassName="relative group block"
                button={
                  <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-primary rounded-full dark:bg-gray-600">
                    <span className="font-medium text-white dark:text-gray-300">{userAbrev}</span>
                  </div>
                }
              >
                <ul className="text-dark dark:text-white-dark !py-0 w-[230px] font-semibold dark:text-white-light/90">
                  <li>
                    <div className="flex items-center px-4 py-4">
                      <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-orange-500 rounded-full dark:bg-gray-600">
                        <span className="font-medium text-white dark:text-gray-300">{userAbrev}</span>
                      </div>
                      <div className="ltr:pl-4 rtl:pr-4">
                        <h4 className="text-base">
                          {userName}
                          <span className="text-xs bg-success-light rounded text-success px-1 ltr:ml-2 rtl:ml-2">✓</span>
                        </h4>
                        {/* <button type="button" className="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white">
                          johndoe@gmail.com
                        </button> */}
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="/user/signature" className="dark:hover:text-white">
                      <CertificateDuoIcon className="ltr:mr-2 rtl:ml-2" />
                      Assinatura
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/reset-password" className="dark:hover:text-white">
                      <PasswordDuoIcon className="ltr:mr-2 rtl:ml-2" />
                      Alterar senha
                    </Link>
                  </li>

                  <li className="border-t border-white-light dark:border-white-light/10">
                    <Link to="#" onClick={logout} className="text-danger !py-3">
                      <svg className="ltr:mr-2 rtl:ml-2 rotate-90" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.5"
                          d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      Sair
                    </Link>
                  </li>
                </ul>
              </Dropdown>
            </div>
          </div>
        </div>

        {/* horizontal menu */}
        <ul className="horizontal-menu hidden py-1.5 font-semibold px-6 lg:space-x-1.5 xl:space-x-8 rtl:space-x-reverse bg-white border-t border-[#ebedf2] dark:border-[#191e3a] dark:bg-black text-black dark:text-white-dark">
          {/* Patient Menu */}
          {userMenus.map((menu) => {
            if (menu.submenus?.length) {
              return (
                <li key={menu.id} className="menu nav-item relative">
                  <button type="button" className="nav-link">
                    <div className="flex items-center">
                      {getMenuIcon(menu.name as string)}
                      <span className="px-1">{menu.name}</span>
                    </div>
                    <div className="right_arrow">
                      <ArrowDownIcon size={16} />
                    </div>
                  </button>
                  <ul className="sub-menu">
                    {menu.submenus.map((sm) => {
                      return (
                        <li key={sm.id}>
                          <NavLink onClick={() => setCurrentMenu(sm.id)} id={sm.id} to={`${sm.path}`}>
                            {sm.name}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            }
            return (
              <li key={menu.id} className="menu nav-item relative">
                <NavLink onClick={() => setCurrentMenu(menu.id)} id={menu.id} className="nav-link" to={`${menu.path}`}>
                  <div className="flex items-center">
                    {getMenuIcon(menu.name as string)}
                    <span className="px-1">{menu.name}</span>
                  </div>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </header>
  );
};

export default Header;
